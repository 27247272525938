import logo from './logo.svg';
import './App.css';
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/about_drop/Services";
import Our_guiding_principles from "./pages/about_drop/Our_guiding_principles";
import Principal from "./pages/staff_drop/Principal";
import Contact from "./pages/Contact";
import Faculty from "./pages/Faculty";
import Csca from "./pages/Csca";
import Gallery from "./pages/Gallery";
import Department from "./pages/Department";
import Students from "./pages/Students-achievements";
import Naac from "./pages/Naac";
import PTA from "./pages/PTA";
import Committees from "./pages/Committees";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Chemistry from "./pages/staff_drop/Chemistry";
import Economics from "./pages/staff_drop/Economics";
import Commerce from "./pages/staff_drop/Commerce";
import Hindi from "./pages/staff_drop/Hindi";
import Math from "./pages/staff_drop/Math";
import Physics from "./pages/staff_drop/Physics";
import English from "./pages/staff_drop/English";
import History from "./pages/staff_drop/History";
import AQAR from "./pages/AQAR";
import Administationstaff from "./pages/Administationstaff";
import AcadimicsCalender from "./pages/AcadimicsCalender";
import OSA from "./pages/OSA";
import Scholarship from "./pages/Scholarship";
import Iqac from "./pages/Iqac";
import Eco_club from "./pages/Eco_club";
import Energy_Club from "./pages/Energy_Club";
import Youth_red_cross from "./pages/Youth_red_cross";
import Electoral_literacy_clubs from "./pages/Electoral_literacy_clubs";
import Red_ribbon_club from "./pages/Red_ribbon_club";
import Road_safety_club from "./pages/Road_safety_club";
import Ncc from "./pages/Ncc";
import Nss from "./pages/Nss";
import Ranger_and_rover from "./pages/Ranger_and_rover";
import College_magazine from "./pages/College_magazine";
import E_learning from "./pages/E_learning";
import Aishe from "./pages/Aishe";
import Ssr from "./pages/Ssr";
import Ugc from "./pages/Ugc";
import Sparsh from "./pages/Sparsh";
import Career_guidance_cell from "./pages/Career_guidance_cell";
import Evaluation from "./pages/Evaluation";
import Library from "./pages/Library";
import Political_science from "./pages/staff_drop/Political_science";
function App() {
  return (
    <Router>
    <Routes>
      <Route exact path="/" element={<Home />}></Route>
      <Route exact path="/about" element={<About />}></Route>
      <Route exact path="/services" element={<Services />}></Route>
      <Route exact path="/our-guiding-principles" element={<Our_guiding_principles />}></Route>
      <Route exact path="/principal" element={<Principal />}></Route>
      <Route exact path="/contact" element={<Contact />}></Route>
      <Route exact path="/faculty" element={<Faculty />}></Route>
      <Route exact path="/csca" element={<Csca />}></Route>
      <Route exact path="/gallery" element={<Gallery />}></Route>
      <Route exact path="/department" element={<Department />}></Route>
      <Route exact path="/contact" element={<Contact />}></Route>
      <Route exact path="/students-achievements" element={<Students />}></Route>
      <Route exact path="/naac" element={<Naac />}></Route>
      <Route exact path="/PTA" element={<PTA />}></Route>
      <Route exact path="/committees" element={<Committees />}></Route>
      <Route exact path="/chemistry" element={<Chemistry />}></Route>
      <Route exact path="/economics" element={<Economics />}></Route>
      <Route exact path="/commerce" element={<Commerce />}></Route>
      <Route exact path="/hindi" element={<Hindi />}></Route>
      <Route exact path="/math" element={<Math />}></Route>
      <Route exact path="/physics" element={<Physics />}></Route>
      <Route exact path="/english" element={<English />}></Route>
      <Route exact path="/history" element={<History />}></Route>
      <Route exact path="/AQAR" element={<AQAR />}></Route>
      <Route exact path="/administationstaff" element={<Administationstaff />}></Route>
      <Route exact path="/acadimicsCalender" element={<AcadimicsCalender />}></Route>
      <Route exact path="/osa" element={<OSA />}></Route>
      <Route exact path="/scholarship" element={<Scholarship />}></Route>
      <Route exact path="/iqac" element={<Iqac />}></Route>
      <Route exact path="/eco_club" element={<Eco_club />}></Route>
      <Route exact path="/energy_Club" element={<Energy_Club />}></Route>
      <Route exact path="/youth_red_cross" element={<Youth_red_cross />}></Route>
      <Route exact path="/electoral_literacy_clubs" element={<Electoral_literacy_clubs />}></Route>
      <Route exact path="/red_ribbon_club" element={<Red_ribbon_club />}></Route>
      <Route exact path="/road_safety_club" element={<Road_safety_club />}></Route>
      <Route exact path="/ncc" element={<Ncc />}></Route>
      <Route exact path="/nss" element={<Nss />}></Route>
      <Route exact path="/ranger_and_rover" element={<Ranger_and_rover />}></Route>
      <Route exact path="/college_magazine" element={<College_magazine />}></Route>
      <Route exact path="/e_learning" element={<E_learning />}></Route>
       <Route exact path="/aishe" element={<Aishe />}></Route>
       <Route exact path="/ssr" element={<Ssr />}></Route>
       <Route exact path="/ugc" element={<Ugc />}></Route>
       <Route exact path="/sparsh" element={<Sparsh />}></Route>
       <Route exact path="/career_guidance_cell" element={<Career_guidance_cell />}></Route>
       <Route exact path="/evaluation" element={<Evaluation />}></Route>
       <Route exact path="/library" element={<Library />}></Route>
       <Route exact path="/political_science" element={<Political_science />}></Route>
    </Routes>
  </Router>
  );
}

export default App;
