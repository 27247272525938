import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";
import { useEffect, useState } from 'react';
import NCC from "../images/nss.jpg";

function Nss(){
    const Urlmain = "https://gcbeetan.co.in/admin/uploads/";
    const [user, setUser] = useState([]);
    const fetchData = () => {
      return fetch("https://gcbeetan.co.in/admin/api/api.php/notifications/nss")
            .then((response) => response.json())
            .then((data) => setUser(data));
    }
      useEffect(() => {
      fetchData();
    },[])
    return(
        <>
        <Header />
  <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>NSS</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<div class="container">
<div class="row">
<div class="col-md-4">
<div class="full mt-5">
                        <img src={NCC} alt="#" />
                    </div>
  </div>
  <div class="col-md-8 layout_padding_2 mt-5">
    <p>
    <strong>NSS (National Service Scheme):</strong><br />
    Our National Service Scheme (NSS) unit encourages students to actively engage in community service and social work. By participating in outreach programs, such as health awareness campaigns, education initiatives, and environmental conservation projects, students learn the importance of giving back to society. NSS also fosters personal growth by developing empathy, teamwork, and leadership abilities while providing students with a platform to apply their academic knowledge in real-world settings.

    </p>
  </div>
  </div>
<div class="show-list mt-5 pb-5">
                <ul>
                {user && user.length > 0 && user.map((userObj, index) => (
                    <li><i class="fa fa-check-circle"></i><a href={Urlmain+userObj.myfile} className="list-inner" target="_blank">{userObj.title}</a></li>
                    ))}
                </ul>
</div>
 </div>
        <Footer />
        </>
    );
}

export default Nss;