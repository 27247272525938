import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";
import { useEffect, useState } from 'react';

function Red_ribbon_club(){
    const Urlmain = "https://gcbeetan.co.in/admin/uploads/";
    const [user, setUser] = useState([]);
    const fetchData = () => {
      return fetch("https://gcbeetan.co.in/admin/api/api.php/notifications/red_ribbon_club")
            .then((response) => response.json())
            .then((data) => setUser(data));
    }
      useEffect(() => {
      fetchData();
    },[])
    return(
        <>
        <Header />
  <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>Red Ribbon Club</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<div class="container">
<div class="row">
  <div class="col-md-12 mt-5">
    <p>
    <strong>Red Ribbon Club:</strong><br />
    The Red Ribbon Club is focused on raising awareness about HIV/AIDS and promoting safe and healthy practices. Students participate in education campaigns, workshops, and community outreach programs to reduce stigma and support those affected by HIV/AIDS. The club emphasizes the importance of sexual health education and works towards creating a more inclusive and accepting environment for everyone.

    </p>
  </div>
  </div>
<div class="show-list mt-5 pb-5">
                <ul>
                {user && user.length > 0 && user.map((userObj, index) => (
                    <li><i class="fa fa-check-circle"></i><a href={Urlmain+userObj.myfile} className="list-inner" target="_blank">{userObj.title}</a></li>
                    ))}
                </ul>
</div>
 </div>
        <Footer />
        </>
    );
}

export default Red_ribbon_club;