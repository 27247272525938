import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import topback from "../images/beetitle.png";
import { useEffect, useState } from 'react';
import NCC from "../images/library.jpg";

function Library(){
    const Urlmain = "https://gcbeetan.co.in/admin/uploads/";
    const [user, setUser] = useState([]);
    const fetchData = () => {
      return fetch("https://gcbeetan.co.in/admin/api/api.php/notifications/library")
            .then((response) => response.json())
            .then((data) => setUser(data));
    }
      useEffect(() => {
      fetchData();
    },[])
    return(
        <>
        <Header />
  <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>Library</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<div class="container">
<div class="row">
<div class="col-md-4">
<div class="full mt-5">
                        <img src={NCC} alt="#" />
                    </div>
  </div>
<div class="col-md-8 mt-5">
    <p>
    <strong>Library:</strong><br />
    The college library is a vibrant and resourceful hub that provides students with access to a vast collection of academic resources, including books, journals, magazines, and digital media. Our library offers a conducive and comfortable environment for study and research, equipped with modern amenities such as computers, Wi-Fi, and study spaces. Students can take advantage of the library's extensive digital library to access e-books and online journals from anywhere. Our dedicated librarians are available to assist students in locating resources and provide guidance on research projects. The library also organizes workshops on information literacy and research skills to enhance students' academic experience.
</p>
  </div>
<div class="show-list mt-5 pb-5">
                <ul>
                {user && user.length > 0 && user.map((userObj, index) => (
                    <li><i class="fa fa-check-circle"></i><a href={Urlmain+userObj.myfile} className="list-inner" target="_blank">{userObj.title}</a></li>
                    ))}
                </ul>
</div>
 </div>
 </div>
        <Footer />
        </>
    );
}

export default Library;