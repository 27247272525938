import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import topback from "../images/beetitle.png";
import pdficon from "../images/pdf-icon.jpg";
import AQAR1920 from "../pdf/AQAR2019-20.pdf";
import pdfUGCourses from "../pdf/UG-Courses-Calendar-for-the-Session-2018.pdf";
import calender2019 from "../pdf/UGCoursesCalendarfortheSession2019.pdf";
import SSR from "../pdf/SSR.pdf";
import AQAR202021 from "../pdf/AQAR2020-21.pdf";
import { useEffect, useState } from 'react';
function Career_guidance_cell(){
    const Urlmain = "https://gcbeetan.co.in/admin/uploads/";
    const [user, setUser] = useState([]);
    const fetchData = () => {
      return fetch("https://gcbeetan.co.in/admin/api/api.php/notifications/career_guidance_cell")
            .then((response) => response.json())
            .then((data) => setUser(data));
    }
      useEffect(() => {
      fetchData();
    },[])
    return(
        <>
           <Header />
        <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>Career Guidance Cell</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<h2 className="text-center mt-5 mb-5"></h2>
<section class="container mt-5 mb-5">
<div class="row">
  <div class="col-md-12">
  <strong>Career and Placement Cell:</strong><br />
    <p>
    The Career and Placement Cell at our college is dedicated to helping students transition smoothly from academic life to professional careers. Through personalized career counseling, resume writing workshops, and interview skills training, students receive the guidance they need to succeed in today's competitive job market. The cell also facilitates campus recruitment drives and job fairs, connecting students with potential employers and internship opportunities across various industries.

    </p>
  </div>
  </div>
<div class="show-list mt-5 pb-5">
                <ul>
                {user && user.length > 0 && user.map((userObj, index) => (
                    <li><i class="fa fa-check-circle"></i><a href={Urlmain+userObj.myfile} className="list-inner" target="_blank">{userObj.title}</a></li>
                    ))}
                </ul>
</div>
</section>
<Footer />
        </>
        );

}

export default Career_guidance_cell;