import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";
import { useEffect, useState } from 'react';

function Electoral_literacy_clubs(){
    const Urlmain = "https://gcbeetan.co.in/admin/uploads/";
    const [user, setUser] = useState([]);
    const fetchData = () => {
      return fetch("https://gcbeetan.co.in/admin/api/api.php/notifications/electoral_literacy_clubs")
            .then((response) => response.json())
            .then((data) => setUser(data));
    }
      useEffect(() => {
      fetchData();
    },[])
    return(
        <>
        <Header />
  <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>Electoral Literacy Clubs</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<div class="container">
<div class="row">
  <div class="col-md-12 mt-5">
    <p>
    <strong>Electoral Literacy Club:</strong><br />
    The Electoral Literacy Club is dedicated to educating students about the democratic process and the importance of voting. Through interactive workshops, debates, and simulations, students gain a deeper understanding of their rights and responsibilities as citizens. The club also organizes voter registration drives and awareness campaigns to promote civic participation and encourage informed decision-making in elections.

    </p>
  </div>
  </div>
<div class="show-list mt-5 pb-5">
                <ul>
                {user && user.length > 0 && user.map((userObj, index) => (
                    <li><i class="fa fa-check-circle"></i><a href={Urlmain+userObj.myfile} className="list-inner" target="_blank">{userObj.title}</a></li>
                    ))}
                </ul>
</div>
 </div>
        <Footer />
        </>
    );
}

export default Electoral_literacy_clubs;