import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import SliderOne from "../images/slider-one.jpg";
import topback from "../images/beetitle.png";
import AboutIMG from "../images/principalbeetan.JPG";
import Mission from "../images/mission.png";
import Vission from "../images/vission.png";
function Committees(){
    return(
        <>
        <Header />
        <div className="section">
        {/* <!--Video Section--> */}
<section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>Committees</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
 </div>
 <div class="container mt-5">
  <div class="row">
    <div class="col-md-6">
      <div class="committee-card">
        <div class="card-header">
          <h3 class="m-0">College Advisory &CSCA Committee </h3>
        </div>
        <div class="card-body">
          <ul>
            <li>Mr. Gurdev Singh </li>
            <li>Dr. Uma Rani</li>
            <li>Dr. Daleep Bali</li>
            <li>Dr. Gaurav Sharma</li>
            <li>Ms. Richa Sharma</li>
            <li>Ms. Sunita Rani</li>
            <li>Sh. Piar Chand (Supdt. Gd-1)</li>
          </ul>
        </div>
      </div>
    </div>
   <div class="col-md-6">
      <div class="committee-card">
        <div class="card-header">
          <h3 class="m-0">Prospectus & College Online Admission Committee</h3>
        </div>
        <div class="card-body">
          <ul>
            <li>Dr. Uma Rani</li>
            <li>Dr. Daleep Bali</li>
            <li>Dr. Gaurav Sharma</li>
            <li>Dr. Reena Chandel</li>
            <li>Ms. Richa Sharma</li>
            <li>Ms Gurjeet Kaur</li>
            <li>Mr. Butta Ram</li>
            <li>Mr. Ramesh Kumar</li>
            <li>Sh. Pawan Kumar (Asst. Libraria</li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="container mt-5">
  <div class="row">
    <div class="col-md-6">
      <div class="committee-card">
        <div class="card-header">
          <h3 class="m-0">Anti Ragging and Discipline Committee</h3>
        </div>
        <div class="card-body">
          <ul>
            <li>Mr. Gurdev Singh </li>
            <li>Dr. Uma Rani </li>
            <li>Dr. Daleep Bali</li>
            <li>Dr. Gaurav Sharma </li>
            <li>Dr. Reena Chandel</li>
            <li><h6>RUSA 2.0 </h6></li>
            <li>Dr. Gaurav Sharma</li>
            <li>Ms. Gurjeet Kaur</li>
            <li>Sh. Ashok Kumar (Storekeeper)</li>
            <li><h6>Time Table Committee</h6></li>
            <li>Dr. Gaurav Sharma </li>
            <li>Mr. Ramesh Kumar</li>
          </ul>
        </div>
      </div>
    </div>
   <div class="col-md-6">
      <div class="committee-card">
        <div class="card-header">
          <h3 class="m-0">Internal Quality Assurance Cell (IQAC) / NAAC Committee</h3>
        </div>
        <div class="card-body">
          <ul>
            <li>Principal- Chairperson</li>
            <li>Mr. Gurdev Singh </li>
            <li>Dr. Daleep Bali</li>
            <li>Dr. Gaurav Sharma</li>
            <li>Dr. Reena Chandel </li>
            <li>Ms Richa Sharma</li>
            <li>Ms. Gurjeet Kaur</li>
            <li>Ms. Sunita Rani (Librarian)</li>
            <li>Ms. Abhilasha Sharda (Retired Principal)</li>
            <li>Dr. Y.P. Saini (Retired Professor)</li>
            <li>Sh. Ashok Kumar (Superintendent Grade-II)</li>
            <li>President CSCA for 2023-24</li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="container mt-5">
  <div class="row">
    <div class="col-md-6">
      <div class="committee-card">
        <div class="card-header">
          <h3 class="m-0">Unnat Bharat Abhiyan Cell / Swachh Bharat Abhiyan Ek Bharat Shreshtha Bharat Cell/ PM- USHA &UGC Cell</h3>
        </div>
        <div class="card-body">
          <ul>
            <li>Dr. Reena Chandel</li>
            <li>Ms. Richa Sharma</li>
            <li>Mr. Butta Ram</li>
            <li>Mr. Ramesh Kumar</li>
            <li><h6>Scholarship/Fee Concession Committee</h6></li>
            <li>Mr. Gurmel Singh</li>
            <li>Ms. Gurjeet Kaur</li>
            <li>Ms. Jyoti Sharma</li>
            <li><h6>Land and Building Affairs, College Development & Repair Committee</h6></li>
            <li>Ms Richa Sharma</li>
            <li>Ms. Gurjeet Kaur</li>
            <li>Mr. Ramesh Kumar</li>
            <li>Sh. Ashok Kumar (Storekeeper)</li>
          </ul>
        </div>
      </div>
    </div>
   <div class="col-md-6">
      <div class="committee-card">
        <div class="card-header">
          <h3 class="m-0">Water/Electricity/ Furniture Committee</h3>
        </div>
        <div class="card-body">
          <ul>
            <li>Dr. Daleep Bali </li>
            <li>Mr. Butta Ram</li>
            <li>Mr. Ramesh Kumar</li>
            <li>Dr. Gaurav Sharma</li>
            <li>Sh. Ashok Kumar (Superintendent Grade-II)</li>
            <li><h6>General Purchase Committee and Project Monitoring Committee (RUSA)</h6></li>
            <li>Mr. Gurdev Singh</li>
            <li>Dr. Daleep Bali</li>
            <li>Dr. Gaurav Sharma</li>
            <li>Dr. Reena Chandel</li>
            <li>Ms. Sunita Rani (Librarian)</li>
            <li>Sh. Ashok Kumar (Superintendent Grade-II)</li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="container mt-5">
  <div class="row">
    <div class="col-md-6">
      <div class="committee-card">
        <div class="card-header">
          <h3 class="m-0">Library Committee</h3>
        </div>
        <div class="card-body">
          <ul>
            <li>Dr. Uma Rani</li>
            <li>Mr. Gurmel Singh</li>
            <li>Ms. Gurjeet Kaur</li>
            <li>Ms. Sunita Rani (Librarian)</li>
            <li>Sh. Pawan Kumar (Assistant Librarian)</li>
            <li><h6>AISHE & Website Committee</h6></li>
            <li>Mr. Gurmel Singh</li>
            <li>Mr. Ramesh Kumar</li>
            <li><h6>Press/Photography Committee</h6></li>
            <li>Dr. Reena Chandel</li>
            <li>Mr. Ramesh Kumar</li>
            <li>Ms. Jyoti Sharma</li>
          </ul>
        </div>
      </div>
    </div>
   <div class="col-md-6">
      <div class="committee-card">
        <div class="card-header">
          <h3 class="m-0">CSCA Committee</h3>
        </div>
        <div class="card-body">
          <ul>
            <li>Mr. Gurdev Singh</li>
            <li>Dr. Daleep Bali</li>
            <li>Mr. Gurmel Singh</li>
            <li><h6>Cultural Activities Committee</h6></li>
            <li>Dr. Uma Rani</li>
            <li>Dr. Reena Chandel</li>
            <li>Ms. Gurjeet Kaur </li>
            <li>Mr. Ramesh Kumar</li>
            <li>Ms. Jyoti Sharma </li>
            <li>Ms. Sunita Rani (Librarian)</li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="container mt-5">
  <div class="row">
    <div class="col-md-6">
      <div class="committee-card">
        <div class="card-header">
          <h3 class="m-0">SPARSH (Sensitization, Prevention& Redressal of Sexual Harassment) Committee
&Counseling Cell for Adolescents</h3>
        </div>
        <div class="card-body">
          <ul>
            <li>Dr. Uma Rani</li>
            <li>Dr. Reena Chandel</li>
            <li>Ms. Gurjeet Kaur</li>
            <li>Ms. Sunita Rani (Librarian)</li>
            <li><h6>Grievances Redressal Cell (matters related to students’ welfare)</h6></li>
            <li>Dr. Reena Chandel </li>
            <li>Mr. Butta Ram</li>
            <li>Sh. Ashok Kumar (Superintendent Grade-II)(Student Member)</li>
          </ul>
        </div>
      </div>
    </div>
   <div class="col-md-6">
      <div class="committee-card">
        <div class="card-header">
          <h3 class="m-0">Mid-Term Exams, University Result Discrepancy /Student Migration/Roll Back/Subject
Change & Re-admission Committee</h3>
        </div>
        <div class="card-body">
          <ul>
            <li>Dr. Uma Rani</li>
            <li>Dr. Reena Chandel</li>
            <li>Mr. Ramesh Kumar</li>
            <li>Ms. Jyoti Sharma</li>
            <li>Sh. Pawan Kumar (Asst. Librarian)</li>
            <li><h6>College NEP Committee/ABC/SWAYAM Cell</h6></li>
            <li>Mr. Gurmel Singh </li>
            <li>Mr. Ramesh Kumar</li>
            <li>Ms. Jyoti Sharma</li>
            
          </ul>
        </div>
      </div>
    </div>
  </div>

</div>


<div class="container mt-5">
  <div class="row">
    <div class="col-md-6">
      <div class="committee-card">
        <div class="card-header">
          <h3 class="m-0">College Canteen Committee</h3>
        </div>
        <div class="card-body">
          <ul>
            <li>Dr. Gaurav Sharma</li>
            <li>Ms Richa Sharma</li>
            <li>Ms. Gurjeet Kaur</li>
            <li>Sh. Piar Chand (Supdt. Gd-1)</li>
            <li><h6>College Magazine (Editorial Board)</h6></li>
            <li>Dr. Uma Rani, Chief Editor cum Editor (Sanskrit)</li>
            <li>Dr. Daleep Bali (English)</li>
            <li>Dr. Reena Chandel (Hindi)</li>
            <li>Ms. Richa Sharma (Science)</li>
            <li>Mr. Gurmel Singh (Planning)</li>
            <li>Mr. Ramesh Kumar (Pahari)</li>
          </ul>
        </div>
      </div>
    </div>
   <div class="col-md-6">
      <div class="committee-card">
        <div class="card-header">
          <h3 class="m-0">Educational Tour Committee</h3>
        </div>
        <div class="card-body">
          <ul>
            <li>Dr. Uma Rani</li>
            <li>Ms. Gurjeet Kaur</li>
            <li>Mr. Ramesh Kumar</li>
            <li>Ms. Sunita Rani (Librarian)</li>
            <li><h6>National Cadet Corps (N.C.C.) Officer</h6></li>
            <li>Mr. Ramesh Kumar</li>
            <li><h6>National Service Scheme (N.S.S.) </h6></li>
            <li>Dr. Daleep Bali (Nodal Officer)</li>
            <li>Sh. Ashok Kumar (Storekeeper)</li>
            <li>Sh. Karam Chand (Mali)</li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</div>
<Footer />
        </>
    );
}

export default Committees;