import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";
import { useEffect, useState } from 'react';

function Iqac(){
    const Urlmain = "https://gcbeetan.co.in/admin/uploads/";
    const [user, setUser] = useState([]);
    const fetchData = () => {
      return fetch("https://gcbeetan.co.in/admin/api/api.php/notifications/iqac")
            .then((response) => response.json())
            .then((data) => setUser(data));
    }
      useEffect(() => {
      fetchData();
    },[])
    return(
        <>
        <Header />
  <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>IQAC</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<div class="container">
<div className="title-1qac">
<h2>INTERNAL QUALITY ASSURANCE CELL</h2>
<h4>About IQAC ( Internal Quality Assurance Cell )</h4>
<p>The Internal Quality Assurance Cell (IQAC) is the cell that is formulated for monitoring the quality parameters in GC. The Composition of IQAC members as per the recommendations given by National Assessment Accreditation Committee.</p>
</div>
<div class="show-list mt-5 pb-5">
                <ul>
                {user && user.length > 0 && user.map((userObj, index) => (
                    <li><i class="fa fa-check-circle"></i><a href={Urlmain+userObj.myfile} className="list-inner" target="_blank">{userObj.title}</a></li>
                    ))}
                </ul>
</div>
 </div>
        <Footer />
        </>
    );
}

export default Iqac;