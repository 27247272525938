import React from "react";
import Header from "../../pages/Header";
import Footer from "../../pages/Footer";
import topback from "../../images/beetitle.png";
import Teacherimg from "../../images/teacher/Butta_Sir.jpg";
import { useEffect, useState } from 'react';

function Political_science(){
  const Urlmain = "https://gcbeetan.co.in/admin/uploads/";
  const [user, setUser] = useState([]);
  const fetchData = () => {
    return fetch("https://gcbeetan.co.in/admin/api/api.php/notifications/political_science")
          .then((response) => response.json())
          .then((data) => setUser(data));
  }
    useEffect(() => {
    fetchData();
  },[])
    return(
        <>
        <Header />
  <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>Political Science</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<section class="our-webcoderskull mt-5">
  <div class="container">
    <div class="section-text">
    <p>
    The Department of Political Science, which was established in 1997, is one of the pioneering departments within Sant Dangu Wale Gujjar Govt. College Beetan. Aligned with the directives of H.P. University, Shimla, the department offers Annual System under CBCS B.A. Degrees with Political Science (DSC, DSE, SEC, and GE). <br />
With unwavering dedication, the department remains devoted to delivering high-quality education, aimed at preparing students for diverse career opportunities and ensuring a bright future. Focused on equipping young minds with the essential tools for success, the department also diligently imparts insights into the scientific study of politics and prepares students for the challenges of the wider world beyond academia.

    </p>
    </div>
    </div>
  </section>
<section class="our-webcoderskull">
  <div class="container">
    <div class="row heading heading-icon">
        <h2></h2>
    </div>
    <ul class="row padding-end" style={{paddingLeft: '0px'}}>
      <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style={{height: '349px;'}}>
            <figure><img src={Teacherimg} class="img-responsive" alt="" /></figure>
            <h3><a href="#">Mr. Butta Ram</a></h3>
            <p>Assistant professor</p>
            <p>(Department of Political Science)</p>
            {/* <ul class="follow-us clearfix">
              <li><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
              <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
              <li><a href="#"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
            </ul> */}
          </div>
      </li>
    </ul>
    <ul className="list-upload">
                {user && user.length > 0 && user.map((userObj, index) => (
                    <li><i class="fa fa-check-circle"></i><a href={Urlmain+userObj.myfile} className="list-inner" target="_blank">{userObj.title}</a></li>
                    ))}
                </ul>
  </div>
</section>
        <Footer />
        </>
    );
}

export default Political_science;