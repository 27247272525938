import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";
import { useEffect, useState } from 'react';
import NCC from "../images/ranger.jpg";

function Ranger_and_rover(){
    const Urlmain = "https://gcbeetan.co.in/admin/uploads/";
    const [user, setUser] = useState([]);
    const fetchData = () => {
      return fetch("https://gcbeetan.co.in/admin/api/api.php/notifications/ranger_and_rover")
            .then((response) => response.json())
            .then((data) => setUser(data));
    }
      useEffect(() => {
      fetchData();
    },[])
    return(
        <>
        <Header />
  <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>Ranger and Rover</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<div class="container">
<div class="row">
<div class="col-md-4">
<div class="full mt-5">
                        <img src={NCC} alt="#" />
                    </div>
  </div>
  <div class="col-md-8 layout_padding_2 mt-5">
    <p>
    <strong>Ranger and Rover:</strong><br />
    The Ranger and Rover program at our college fosters a spirit of adventure and exploration among students. By engaging in outdoor activities such as hiking, camping, and trekking, students develop resilience, confidence, and a deep connection with nature. Additionally, students participate in community service projects, building their teamwork skills and contributing positively to society. This program provides students with a well-rounded experience that enhances their personal and professional growth.
    </p>
  </div>
  </div>
<div class="show-list mt-5 pb-5">
                <ul>
                {user && user.length > 0 && user.map((userObj, index) => (
                    <li><i class="fa fa-check-circle"></i><a href={Urlmain+userObj.myfile} className="list-inner" target="_blank">{userObj.title}</a></li>
                    ))}
                </ul>
</div>
 </div>
        <Footer />
        </>
    );
}

export default Ranger_and_rover;