import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import topback from "../images/beetitle.png";
import { useEffect, useState } from 'react';

function College_magazine(){
    const Urlmain = "https://gcbeetan.co.in/admin/uploads/";
    const [user, setUser] = useState([]);
    const fetchData = () => {
      return fetch("https://gcbeetan.co.in/admin/api/api.php/notifications/college_magazine")
            .then((response) => response.json())
            .then((data) => setUser(data));
    }
      useEffect(() => {
      fetchData();
    },[])
    return(
        <>
        <Header />
  <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>College Magazine</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<div class="container">
<div class="row">
  <div class="col-md-12 mt-5">
  <strong>College Magazine:</strong><br />
    <p>
    The college magazine is a creative and informative publication that showcases the talents and achievements of our students and faculty. It features a diverse range of content, including articles, essays, poetry, artwork, and photography contributed by members of the college community. The magazine serves as a platform for students to express their thoughts, share experiences, and celebrate academic and extracurricular accomplishments. Through its pages, readers can stay updated on college events, news, and milestones. The magazine also promotes critical thinking and creativity among students, providing them with opportunities to hone their writing and editorial skills.
    </p>
  </div>
  </div>
<div class="show-list mt-5 pb-5">
                <ul>
                {user && user.length > 0 && user.map((userObj, index) => (
                    <li><i class="fa fa-check-circle"></i><a href={Urlmain+userObj.myfile} className="list-inner" target="_blank">{userObj.title}</a></li>
                    ))}
                </ul>
</div>
 </div>
        <Footer />
        </>
    );
}

export default College_magazine;