import React from "react";
import { Button, Navbar, Container, Nav } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Logo from "../images/logo.jpg";
import Lowder from "../images/loader.gif";
import pdfAcademic from "../pdf/organogram.pdf";
import Bestprectice201920 from "../pdf/Best-Practices-2019-20.pdf";
function Header(){
    return(
        <>
     {/* <!-- LOADER --> */}
  
    {/* <!-- end loader -->
    <!-- END LOADER -->

    <!-- Start header --> */}
    <header className="top-header">
    <p className="logo-text">SDWG Govt. College Beetan (Institution of Excellence)</p>
        <nav className="navbar header-nav navbar-expand-lg">
            <div className="container-fluid">
                <a className="navbar-brand" href="/"><img src={Logo} alt="image" />
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-wd" aria-controls="navbar-wd" aria-expanded="false" aria-label="Toggle navigation">
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbar-wd">
                    <ul className="navbar-nav">
                        <li><a href="/" className="nav-link active">Home</a></li>
                         <li id="drop-menu"><Link to="#" className="nav-link open-student">Student Zone<span><i class="fa fa-angle-down"></i></span></Link>
                           <ul className="drop-main" id="open-student">
                            <li><Link to="/e_learning" className="nav-link">e- learning resources</Link></li>
                            <li><Link to="/csca" className="nav-link">CSCA</Link></li>
                            <li><Link to="/college_magazine" className="nav-link">College Magazine</Link></li>
                            <li><Link to="/scholarship" className="nav-link">Scholarship</Link></li>
                            <li className="second-menu"><Link to="#" className="nav-link">clubs and societies <i class='fa fa-angle-right' style={{fontsize:'24px'}}></i></Link>
                            <ul className="second-inner">
                                <li><Link to="/eco_club" className="nav-link">Eco club</Link></li>
                                <li><Link to="/energy_Club" className="nav-link">Energy Club</Link></li>
                                <li><Link to="/youth_red_cross" className="nav-link">Youth Red Cross</Link></li>
                                <li><Link to="/electoral_literacy_clubs" className="nav-link">Electoral Literacy Clubs</Link></li>
                                <li><Link to="/red_ribbon_club" className="nav-link">Red Ribbon club</Link></li>
                                <li><Link to="/road_safety_club" className="nav-link">Road Safety Club</Link></li>
                            </ul>
                            </li>
                            <li><Link to="/nss" className="nav-link">NSS</Link></li>
                            <li><Link to="/ncc" className="nav-link">NCC</Link></li>
                            <li><Link to="/ranger_and_rover" className="nav-link">Rangers & Rovers</Link></li>
                            <li><Link to="/career_guidance_cell" className="nav-link">Career guidance & placement cell</Link></li>
                            <li><Link to="/college_magazine" className="nav-link">College Magazine</Link></li>
                            <li><Link to="#" className="nav-link">Academic Bank of credits</Link></li>
                           </ul>
                        </li>
                        <li id="drop-menu"><Link to="#" className="nav-link open-staff">Staff <span><i class="fa fa-angle-down"></i></span></Link>
                           <ul className="drop-main" id="open-staff">
                            <li><Link to="/principal" className="nav-link">Principal</Link></li>
                            <li><Link to="/faculty"className="nav-link">Facutly</Link></li>
                            <li><Link to="/administationstaff"className="nav-link">Administation Staff</Link></li>
                          </ul>
                        </li>
                        {/* <li><Link to="/csca" className="nav-link">CSCA</Link></li> */}
                        <li id="drop-menu"><Link to="/iqac" className="nav-link">IQAC <span><i class="fa fa-angle-down"></i></span></Link>
                           <ul className="drop-main">
                           <li><Link to="/naac"className="nav-link">Naac</Link></li>
                             <li><Link to="/AQAR"className="nav-link">AQAR</Link></li>
                            <li><Link to="/aishe"className="nav-link">AISHE</Link></li>
                            <li><Link to="/ssr"className="nav-link">SSR</Link></li>
                            <li className="second-menu"><Link to="#" className="nav-link">Institutional Best Practices <i class='fa fa-angle-right' style={{fontsize:'24px'}}></i></Link>
                            <ul className="second-inner">
                                <li><Link to={Bestprectice201920} target="_blank" className="nav-link">2019-20</Link></li>
                            </ul>
                            </li>
                            <li><Link to="/ugc"className="nav-link">UGC/NEP</Link></li>
                           </ul>
                        </li>
                        <li id="drop-menu"><Link to="#" className="nav-link open-academics">Academics <span><i class="fa fa-angle-down"></i></span></Link>
                        <ul className="drop-main" id="closed-academics">
                            <li className="second-menu"><Link to="#" className="nav-link">Departments <i class='fa fa-angle-right' style={{fontsize:'24px'}}></i></Link>
                            <ul className="second-inner">
                                <li><Link to="/chemistry" className="nav-link">Chemistry</Link></li>
                                <li><Link to="/economics" className="nav-link">Economics</Link></li>
                                <li><Link to="/commerce" className="nav-link">Commerce</Link></li>
                                <li><Link to="/hindi" className="nav-link">Hindi</Link></li>
                                <li><Link to="/math" className="nav-link">Math</Link></li>
                                <li><Link to="/physics" className="nav-link">Physics</Link></li>
                                <li><Link to="/political_science" className="nav-link">Political Science</Link></li>
                                <li><Link to="/english" className="nav-link">English</Link></li>
                                <li><Link to="/history" className="nav-link">History</Link></li>
                            </ul>
                            </li>
                            <li><Link to="/acadimicsCalender" className="nav-link">Academic Calendar</Link></li>
                            <li><Link to="/evaluation" className="nav-link">Evaluation / Examination / CELL </Link></li>
                        </ul>
                        </li>
                        <li id="drop-menu"><Link to="/contact" className="nav-link">Contact Us</Link>
                        </li>
						<li id="drop-menu"><Link className="nav-link">Miscellaneous <span><i class="fa fa-angle-down"></i></span></Link>
                           <ul className="drop-main">
                            <li><Link to="/Committees" className="nav-link">COMMITTEES</Link></li>
                            <li><Link to="/sparsh" className="nav-link">SPARSH</Link></li>
                            <li><Link to="/career_guidance_cell" className="nav-link">Career Guidance Cell</Link></li>
                            <li><Link to="/csca" className="nav-link">CSCA</Link></li>
                            <li><Link to="/gallery" className="nav-link">Gallery</Link></li>
                            <li><Link to="/PTA" className="nav-link">PTA</Link></li>
                            <li><Link to="/osa" className="nav-link">OSA</Link></li>
                            <li className="second-menu"><Link to="#" className="nav-link">Downloads <i class='fa fa-angle-right' style={{fontsize:'24px'}}></i></Link>
                            <ul className="second-inner">
                                <li><Link to="#" className="nav-link">For Students</Link></li>
                                <li className="second-menu-inner"><Link to="#" className="nav-link">For Staff <i class='fa fa-angle-down' style={{fontsize:'24px'}}></i></Link>
                                <ul className="second-inner-third">
                                <li><Link to="#" className="nav-link">Noc's</Link></li>
                                <li><Link to="#" className="nav-link">Forms / Proformas</Link></li>
                                <li><Link to="#" className="nav-link">Notification / Letter</Link></li>
                             </ul>
                                </li>
        
                             </ul>
                            </li>
                             <li><Link to="#" className="nav-link">INFRASTRUCTURE</Link></li>
                            <li><Link to="/library" className="nav-link">LIBRARY</Link></li>
                            <li><Link to="/students-achievements"className="nav-link">Achievements</Link></li>
                        </ul>
                        </li>
                     
            <li><a className="nav-link login-btn" href="https://admission.gcbeetan.co.in/">Online Addmission</a></li>
                    </ul>
                </div>
                {/* <div className="search-box">
                    <input type="text" className="search-txt" placeholder="Search" />
                    <a className="search-btn">
                        <img src="images/search_icon.png" alt="#" />
                    </a>
                </div> */}
           
            </div>
        
        </nav>

    </header>
    {/* <!-- End header --> */}
 </>
    );
}
export default Header;