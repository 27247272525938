import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";
import { useEffect, useState } from 'react';
import NCC from "../images/road.jpg";

function Road_safety_club(){
    const Urlmain = "https://gcbeetan.co.in/admin/uploads/";
    const [user, setUser] = useState([]);
    const fetchData = () => {
      return fetch("https://gcbeetan.co.in/admin/api/api.php/notifications/road_safety_club")
            .then((response) => response.json())
            .then((data) => setUser(data));
    }
      useEffect(() => {
      fetchData();
    },[])
    return(
        <>
        <Header />
  <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>Road Safety Club</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<div class="container">
<div class="row">
<div class="col-md-4">
<div class="full mt-5">
                        <img src={NCC} alt="#" />
                    </div>
  </div>
<div class="col-md-8 mt-5">
    <p>
    <strong>Road Safety Club:</strong><br />
    The Road Safety Club promotes responsible and safe behavior on the road, aiming to reduce accidents and injuries. Through awareness campaigns, workshops, and safety demonstrations, students learn about the importance of obeying traffic rules, the impact of distracted driving, and other road safety concerns. The club's initiatives help create a culture of safety, contributing to the well-being of the community and saving lives.
</p>
  </div>
<div class="show-list mt-5 pb-5">
                <ul>
                {user && user.length > 0 && user.map((userObj, index) => (
                    <li><i class="fa fa-check-circle"></i><a href={Urlmain+userObj.myfile} className="list-inner" target="_blank">{userObj.title}</a></li>
                    ))}
                </ul>
</div>
 </div>
 </div>
        <Footer />
        </>
    );
}

export default Road_safety_club;