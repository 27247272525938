import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";
import { useEffect, useState } from 'react';
import NCC from "../images/redcross.jpg";

function Youth_red_cross(){
    const Urlmain = "https://gcbeetan.co.in/admin/uploads/";
    const [user, setUser] = useState([]);
    const fetchData = () => {
      return fetch("https://gcbeetan.co.in/admin/api/api.php/notifications/youth_red_cross")
            .then((response) => response.json())
            .then((data) => setUser(data));
    }
      useEffect(() => {
      fetchData();
    },[])
    return(
        <>
        <Header />
  <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>Youth Red Cross</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<div class="container">
<div class="row">
<div class="col-md-4">
<div class="full mt-5">
                        <img src={NCC} alt="#" />
                    </div>
  </div>
<div class="col-md-8 mt-5">
    <p>
    <strong>Youth Red Cross Club:</strong><br />
    The Youth Red Cross Club empowers students to make a positive impact through humanitarian efforts and health initiatives. By participating in first aid training, blood donation drives, and community outreach programs, students gain a better understanding of health-related issues and how to address them effectively. The club fosters values such as compassion, empathy, and civic responsibility, inspiring students to become active contributors to the well-being of their communities.
</p>
  </div>
 
<div class="show-list mt-5 pb-5">
                <ul>
                {user && user.length > 0 && user.map((userObj, index) => (
                    <li><i class="fa fa-check-circle"></i><a href={Urlmain+userObj.myfile} className="list-inner" target="_blank">{userObj.title}</a></li>
                    ))}
                </ul>
</div>
 </div>
 </div>
        <Footer />
        </>
    );
}

export default Youth_red_cross;